<template>
  <b-card class="mt-0 pt-0">
    <validation-observer
      ref="accountRules"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2 ml-0 pl-0"
        >
          <h3
            class="mb-1 title-tab color-text-dark"
            style="font-weight: 700; color: #181E38; font-size: 28px;"
          >
            Billing information
          </h3>
          <span
            class=" mt-2 w-100"
            style="color: #656B85; font-size: 16px; display: block;"
          >
          <b-spinner style="color: #e3e3e3;" label="Loading..." variant="" v-if="loadErrors" />{{ loadErrors ? '' : countFreeLicenses }}
          Free license</span>
          <span
            class=" mt-25 w-100"
            style="color: #656B85; font-size: 16px; display: block;"
          >
          <b-spinner style="color: #e3e3e3;" label="Loading..." variant="" v-if="loadErrors" />{{ loadErrors ? '' : countPaidLicenses }}
          Paid license</span>

          <div class="w-100 mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              :to="{ name: 'memberships-paid' }"
              :style="loadLicenses ? 'cursor: no-drop;' : ''"
              :disabled="loadLicenses ? true : false"
            >
              View licenses details
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loadLicenses ? true : customerId ? false : true"
              :style="loadLicenses ? 'cursor: no-drop;' : ''"
              variant="info"
              class="ml-2"
              @click="redirectToBilling(customerId)"
            >
              Manage subscriptions
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class=" pt-0 mt-1 px-1"
              :to="{ name: 'memberships-paid' }"
              :style="loadLicenses ? 'cursor: no-drop; height: 36px; border-radius: 10px; padding-top: 5px !important;' : 'height: 36px; border-radius: 10px; padding-top: 5px !important;'"
              :disabled="loadLicenses ? true : false"
              variant="info"
            >
              <span
                class="align-middle"
                style="font-size: 16px; padding-top: 1px;"
              >View licenses details</span>
            </b-button> -->

            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="pt-0 mt-1 px-1 ml-2"
              :disabled="loadLicenses ? true : customerId ? false : true"
              :style="loadLicenses ? 'cursor: no-drop; height: 36px; border-radius: 10px; padding-top: 5px !important;' : 'height: 36px; border-radius: 10px; padding-top: 5px !important;'"
              variant="info"
              @click="redirectToBilling(customerId)"
            >
              <span
                class="align-middle"
                style="font-size: 16px; padding-top: 1px;"
              >Manage subscriptions</span>
            </b-button> -->
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar,
  BFormTextarea, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,

    // Form Wizard
    FormWizard,
    TabContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      password: '',
      passwordConfirm: '',
      email: '',
      usuarioData: {},
      fileImg: '',
      optionsSelected: [
        { text: 'Orange', value: 'orange' },
      ],
      loadingWizard: false,
      optionsCountry: [
        { title: 'United States' },
        { title: 'Canada' },
        { title: 'Mexico' },
        { title: 'Guatemala' },
        { title: 'Belize' },
        { title: 'Honduras' },
        { title: 'El Salvador' },
        { title: 'Nicaragua' },
        { title: 'Costa Rica' },
        { title: 'Panama' },
      ],

      loadErrors: false,
      loadLicenses: false,
      countPaidLicenses: 0,
      countFreeLicenses: 0
    }
  },
  computed: {
    ...mapState(['cargaAction', 'usuario']),
  },
  watch: {
    profileFile(val) {
      // console.log(val)
      this.fileImg = val
      // this.usuarioData.id = this.usuario.uid
      this.usuarioData.fileImg = this.fileImg
      this.editarGeneralUsuario(this.usuarioData)
      if (val) {
        const reader = new FileReader()
        reader.readAsDataURL(val)
        reader.onload = e => {
          // console.log(e.target.result)
          this.usuarioData.avatar = e.target.result
        }
      } else {
        this.usuarioData.avatar = ''
      }
    },
  },
  methods: {
    ...mapActions(['editarGeneralUsuario', 'cerrarSesion']),
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            // this.loadingWizard = true
            this.submitForm = true
            this.setUsuario()
          } else {
            // this.loadingWizard = false
            this.submitForm = false
          }
        })
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationAdicional() {
      return new Promise((resolve, reject) => {
        this.$refs.adicionalRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    redirectToBilling(customerId) {
      this.loadLicenses = true
      // this.processInvoice = customerId
      // this.processInvoice = idsite
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)

      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({customerId: this.customerId, domain: domain})
      };
  
      this.axios(config)
      .then((response) => {
        // Redirigir al usuario a la URL de facturación de Stripe
        // window.location.href = response
        // console.log(response.data.url);
        var url = response.data.url
        if(url) {
          window.location.href = url

          setTimeout(() => {
            this.loadLicenses = false
          }, 1500)
        }
      })
      .catch(e => console.log(e.message))
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  created() {
    this.loadErrors = true
    this.loadLicenses = true
    this.countPaidLicenses = 0
    this.countFreeLicenses = 0
    const user = auth.currentUser
    if(user) {
      db.collection('Users').doc(user.uid).get()
      .then(userDocCustomer => {
        this.customerId = userDocCustomer.data().customerId ? userDocCustomer.data().customerId : ''
        this.loadLicenses = false
        db.collection('Users').doc(user.uid).collection('projects').get()
        .then(userDoc => {
          userDoc.forEach(eleUserProject => {
            // var obj = eleUserProject.data()
            // obj.id = eleUserProject.id
            // this.userProjects.push(obj)
            db.collection('Projects').doc(eleUserProject.data().id).collection('sites').get()
            .then(queryArchiveProject => {
              queryArchiveProject.forEach(site => {
                if(site.data().plan === 'basic' || site.data().plan === 'Basic' || site.data().plan === 'pro' || site.data().plan === 'Pro' || site.data().plan === 'custom' || site.data().plan === 'Custom' || site.data().plan === 'test' || site.data().plan === 'Test') {
                  this.countPaidLicenses += 1
                } else {
                  this.countFreeLicenses += 1
                }
              })
            })
          })

          setTimeout(() => {
            this.loadErrors = false
          }, 2500);
          // this.countPaidLicenses = 0
        })
      })
    }

    // const user = auth.currentUser

    // const ref = db.collection('Users').doc(user.uid)
    // ref.onSnapshot(doc => {
    //   this.usuarioData = {}
    //   this.usuarioData = {
    //     id: doc.data().uid,
    //     nombre: doc.data().nombre,
    //     contact: doc.data().contact ? doc.data().contact : '',
    //     address1: doc.data().address1 ? doc.data().address1 : '',
    //     address2: doc.data().address2 ? doc.data().address2 : '',
    //     company: doc.data().company ? doc.data().company : '',
    //     country: doc.data().country ? doc.data().country : 'United States',
    //     stateOrRegion: doc.data().stateOrRegion ? doc.data().stateOrRegion : '',
    //     city: doc.data().city ? doc.data().city : '',
    //     zipCode: doc.data().zipCode ? doc.data().zipCode : ''
    //     // firstName: doc.data().firstName ? doc.data().firstName : doc.data().firstName,
    //     // lastName: doc.data().lastName ? doc.data().lastName : '',
    //     // email: doc.data().email,
    //     // role: doc.data().role,
    //     // avatar: doc.data().foto,
    //     // contact: doc.data().contact
    //   }
    //   this.email = doc.data().email
    // })

    // var obj = {
    //   "id": "JvoMs1sb71QmDSUd0jGViDi9kfQ2",
    //   "password": "User@123"
    // };

    // var data = JSON.stringify({
    //   "id": "JvoMs1sb71QmDSUd0jGViDi9kfQ2",
    //   "password": "User@123"
    // });
    // this.$http.get(`https://us-central1-nycpg-69e96.cloudfunctions.net/app/api/auth/update/usuarios/data/${obj.id}/${obj.password}`)
    // .then(res => {
    //     console.log(res.data, res.status)
    // })
    // https://us-central1-nycpg-69e96.cloudfunctions.net/updateAuthUsers

    // var config = {
    //   method: 'post',
    //   url: 'https://us-central1-nycpg-69e96.cloudfunctions.net/app/api/auth/update/usuarios/data',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //   },
    //   data : data
    // };

    // this.axios(config)
    // .then(res => {
    //     console.log(res.data, res.status)
    //     // this.password = ''
    //     // this.passwordConfirm = ''
    // })
    // .catch(e => {
    //     console.log(e)
    //     // this.password = ''
    //     // this.passwordConfirm = ''
    // })
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>

<style>
/* This is a css loader. It's not related to vue-form-wizard */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  /* margin: 60px auto; */
  font-size: 2px;
  position: absolute;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.vs__dropdown-toggle {
  background: #fff !important;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.title-tab {
  font-size: 24px;
  width: 700px;
}
</style>
